<template>
  <b-modal
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    :title="$t('buttons.edit')"
    shadow
    backdrop
    no-close-on-backdrop
    hide-footer
    centered
    size="md"
    @hidden="setModal(false)"
    @ok="setModal(false)"
  >
    <template #default="{ hide }">
      <div>
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-form
            method="POST"
            class="auth-reset-password-form mt-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <b-row>
              <b-col>
                <!-- userFullName -->
                <validation-provider
                  #default="validationContext"
                  name="Name and surname"
                >
                  <b-form-group label-for="userFullName">
                    <template #label> {{$t('name_surname')}} </template>
                    <b-form-input
                      v-model="userData.userFullName"
                      :state="getValidationState(validationContext)"
                      trim
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <!-- dni -->
                <validation-provider
                  #default="validationContext"
                  name="DNI"
                  rules="required|dni"
                >
                  <b-form-group label-for="DNI">
                    <template #label> {{$t('labels.dni')}} </template>
                    <b-form-input
                      v-model="userData.userDni"
                      :state="getValidationState(validationContext)"
                      trim
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <!-- description -->
                <validation-provider
                  #default="validationContext"
                  name="Description"
                  rules="required"
                >
                  <b-form-group label-for="description">
                    <template #label> {{$t('labels.description')}}</template>
                    <b-form-input
                      v-model="userData.description"
                      :state="getValidationState(validationContext)"
                      trim
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                <b-spinner
                  v-if="loadings.editButton"
                  type="grow"
                  label="Spinning"
                  class="mr-1"
                  small
                ></b-spinner>
                <feather-icon v-else icon="EditIcon" class="mr-1" />
                {{ $t("buttons.edit") }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                <feather-icon icon="XIcon" class="mr-1" />
                {{ $t("buttons.cancel") }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { getCurrentInstance, reactive, ref } from '@vue/composition-api'
import formValidation from "@core/comp-functions/forms/form-validation";
import store from "@/store";
import { required } from "@core/utils/validations/validations";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { translatableText } from '@core/utils/utils'
import { axiosErrorHandle } from '@core/utils/errorHandler'

export default {
  name: "promotionEdit",
  components: {
    vSelect,
    BFormInput,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance()

    const toast = useToast(ToastificationContent);

    const isAddNewUserSidebarActive = ref(false);

    const userData = ref({
      userFullName: "",
      userDni: "",
      description: "",
      _id: "",
    });

    const loadings = reactive({
      editButton: false,
    });

    const setModal = (flag) => {
      isAddNewUserSidebarActive.value = flag;
    };
    const setEditData = (data) => {
      userData.value = Object.assign({}, data);
    };
    const resetUserData = () => {
      userData.value = {
        userFullName: "",
        userDni: "",
        description: "",
        _id: "",
      };
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetUserData);

    const onSubmit = async () => {
      try {
        // const validation = this.$refs.simpleRules.validate()
        // if(!validation)return;

        loadings.editButton = true;

        const payload = {
          userFullName: userData.value.userFullName,
          userDni: userData.value.userDni,
          description: userData.value.description,
        };

        const response = await store.dispatch(
          "app-exclusion-list/editExcludeUser",
          { id: userData.value._id, payload }
        );

        emit("fetch-data");

        resetForm();

        setModal(false);

        toast({
          component: ToastificationContent,
          props: {
            title: vm.proxy.$t('user_edited'),
            icon: "EditIcon",
            variant: "success",
          },
        });
      } catch (error) {
        toast({
          component: ToastificationContent,
          props: {
            title: await translatableText({
              text: axiosErrorHandle(error)
            }),
            icon: "XIcon",
            variant: "danger",
          },
        });
      } finally {
        loadings.editButton = false;
      }
    };

    return {
      setModal,
      isAddNewUserSidebarActive,
      getValidationState,
      refFormObserver,
      resetForm,
      userData,
      required,
      resetUserData,
      onSubmit,
      setEditData,
      loadings,
    };
  },
};
</script>

<style scoped></style>
