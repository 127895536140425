import axios from "@axios";
const APP_URL = process.env.VUE_APP_URL;
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPromotions(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${APP_URL}/promotions`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    createPromotion(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${APP_URL}/promotions`, payload)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    removePromotion(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
            .delete(`${APP_URL}/promotions/${id}`)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
      });
    },
  },
};
