<template>
  <b-modal
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    :title="$t('buttons.add')"
    shadow
    backdrop
    no-close-on-backdrop
    hide-footer
    centered
    size="md"
    @hidden="setModal(false)"
    @ok="setModal(false)"
    @show="resetPromotion"
  >
    <template #default="{ hide }">
      <div>
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-form
            method="POST"
            class="auth-reset-password-form mt-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <b-row>
              <b-col>
                <!-- productId -->
                <validation-provider
                  #default="validationContext"
                  name="product"
                  rules="required"
                  class="w-100"
                >
                  <b-col cols="12">
                    <b-form-group
                      :label="$t('labels.products')"
                      label-for="products"
                    >
                      <v-select
                        v-model="promotion.productId"
                        :options="products"
                        label="name"
                        :state="getValidationState(validationContext)"
                        required
                        :loading="productsLoading"
                        :reduce="(option) => option.productId"
                      />
                      <small class="text-danger">{{
                        validationContext.errors[0]
                      }}</small>
                    </b-form-group>
                  </b-col>
                </validation-provider>
                <!-- code -->
                <validation-provider #default="validationContext" name="code">
                  <b-form-group label-for="userFullName">
                    <template #label> {{ $t("labels.promotion_code") }} </template>
                    <b-form-input
                      v-model="promotion.code"
                      :state="getValidationState(validationContext)"
                      trim
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                <b-spinner
                  v-if="loadings.addButton"
                  type="grow"
                  label="Spinning"
                  class="mr-1"
                  small
                ></b-spinner>
                <feather-icon v-else icon="SaveIcon" class="mr-1" />
                {{ $t("buttons.save") }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                <feather-icon icon="XIcon" class="mr-1" />
                {{ $t("buttons.cancel") }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupAppend,
  BTab,
  BTabs,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  getCurrentInstance,
  onMounted,
  reactive,
  ref,
} from "@vue/composition-api";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";
import store from "@/store";
import { required, dni } from "@validations";
import { translatableText } from "@core/utils/utils";
import { axiosErrorHandle } from "@core/utils/errorHandler";

const BACK_URL = process.env.VUE_APP_URL;

export default {
  name: "promotionAdd",
  components: {
    BForm,
    BFormInput,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BTab,
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,
    vSelect,
    BTabs,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
  },
  data() {
    return {};
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance();

    const toast = useToast(ToastificationContent);

    const isAddNewUserSidebarActive = ref(false);

    const promotion = ref({
      code: "",
      productId: "",
    });

    const products = ref([]);
    const productsLoading = ref(false);

    const loadings = reactive({
      addButton: false,
    });

    const setModal = (flag) => {
      isAddNewUserSidebarActive.value = flag;
    };

    const resetPromotion = () => {
      promotion.value = {
        code: "",
        productId: "",
      };
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetPromotion);

    const onSubmit = async () => {
      try {
        // const validation = this.$refs.simpleRules.validate()
        // if(!validation)return;

        loadings.addButton = true;
        const whitelabelId =
          store.state.whitelabelCurrencyNabvar.whitelabel._id;

        const payload = {
          productId: promotion.value.productId,
          code: promotion.value.code,
          whitelabelId,
        };

        const response = await store.dispatch(
          "app-promotion/createPromotion",
          payload
        );

        emit("fetch-data");

        resetForm();

        setModal(false);

        toast({
          component: ToastificationContent,
          props: {
            title: vm.proxy.$t("success_message"),
            icon: "EditIcon",
            variant: "success",
          },
        });
      } catch (error) {
        toast({
          component: ToastificationContent,
          props: {
            title: await translatableText({
              text: axiosErrorHandle(error).toString(),
            }),
            icon: "XIcon",
            variant: "danger",
          },
        });
      } finally {
        loadings.addButton = false;
      }
    };

    const setWhitelabelProducts = async () => {
      products.value = store.state.whitelabelCurrencyNabvar.whitelabel.products.filter(products => products.ispromotion)
    };

    onMounted(() => setWhitelabelProducts());

    return {
      setModal,
      isAddNewUserSidebarActive,
      getValidationState,
      refFormObserver,
      resetForm,
      promotion,
      required,
      dni,
      resetPromotion,
      onSubmit,
      loadings,
      products,
      productsLoading,
    };
  },
};
</script>

<style scoped></style>
