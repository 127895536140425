<template>
  <div>
    <b-row>
      <b-col md="12">
        <!-- Table Container Card -->
        <b-card no-body class="mb-0">
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <b-col
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>{{ $t("labels.show") }}</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  @input="fetchData"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>{{ $t("labels.entries") }}</label>
              </b-col>

              <b-col
                md="6"
                class="d-flex align-items-center justify-content-center mb-1"
              >
                <b-form-input
                  v-model="searchQuery"
                  @input="fetchData"
                  class="d-inline-block mr-1"
                  :placeholder="$t('labels.search')"
                />
                <div>
                  <b-button
                    v-if="isSeePermission({ permission: 'promotion-add' })"
                    variant="primary"
                    class="d-flex align-items-center justify-content-center"
                    @click="addExcludedUser()"
                  >
                    <feather-icon icon="UserPlusIcon" size="16" class="mr-50" />
                    <span class="text-nowrap">{{ $t("buttons.add") }}</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>

          <b-overlay
            :show="loadings.overlay"
            :variant="$store.state.appConfig.layout.skin"
            blur="2"
            spinner-type="grow"
            class="p-50"
            opacity="0.50"
          >
            <b-table
              style="min-height: 300px"
              ref="refUserListTable"
              :items="promotionList"
              responsive
              :fields="tableColumns"
              primary-key="_id"
              :sort-by.sync="sortBy"
              show-empty
              :empty-text="$t('messages.nomatching')"
              :sort-desc.sync="isSortDirDesc"
            >
              <!-- Column: type -->
              <template #cell(code)="data">
                <div class="d-flex justify-content-center">
                  <b-badge>
                    {{data.item.code}}
                  </b-badge>
                </div>
              </template>

              <template #cell(createdAt)="data">
                {{ data.item.createdAt | moment }}
              </template>

              <template #cell(actions)="data">
                <div class="d-flex">
                
<!--                  <b-button-->
<!--                    v-if="isSeePermission({ permission: 'promotion-edit' })"-->
<!--                    variant="primary"-->
<!--                    class="btn-icon rounded-circle mr-1 mb-1"-->
<!--                    v-b-tooltip.hover.top="$t('tooltips.edit')"-->
<!--                    @click="editExcludedUser(data.item)"-->
<!--                    size="sm"-->
<!--                  >-->
<!--                    <feather-icon icon="EditIcon" />-->
<!--                  </b-button>-->

                  <b-button
                    v-if="
                    isSeePermission({ permission: 'promotion-remove' })
                  "
                    variant="primary"
                    class="btn-icon rounded-circle mr-1 mb-1"
                    v-b-tooltip.hover.top="$t('tooltips.delete')"
                    @click="removePromotion(data.item)"
                    size="sm"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </div>
              </template>
            </b-table>
          </b-overlay>

          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >{{ $t("labels.showing") }} {{ dataMeta.from }}
                  {{ $t("labels.to") }} {{ dataMeta.to }} {{ $t("labels.of") }}
                  {{ dataMeta.of }} {{ $t("labels.entries") }}</span
                >
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                  @input="fetchData"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <PromotionAdd ref="promotionAddComponent" @fetch-data="fetchData" />

    <PromotionEdit
      ref="promotionEditComponent"
      @fetch-data="fetchData"
    />

    <PromotionDetail ref="promotionDetailComponent" />
  </div>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BCol,
  BFormInput,
  BLink,
  BMedia,
  BOverlay,
  BPagination,
  BRow,
  BTable,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { computed, onMounted, onUnmounted, reactive, ref } from '@vue/composition-api'
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import PromotionAdd from "@/views/pages/promotions/promotionAdd.vue";
import store from "@/store";
import promotionStoreModule from "@/views/pages/promotions/promotionStoreModule";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";
import PromotionEdit from "@/views/pages/promotions/promotionEdit.vue";
import Swal from "sweetalert2";
import PromotionDetail from "@/views/pages/promotions/promotionDetail.vue";
import { useUtils } from "@core/libs/acl";
import moment from "moment";

const { t } = useI18nUtils();
import { translatableText } from '@core/utils/utils'
import { axiosErrorHandle } from '@core/utils/errorHandler'
const { isSeePermission } = useUtils();
import { getCurrentInstance } from '@vue/composition-api'

export default {
  name: "index",
  components: {
    PromotionDetail,
    PromotionEdit,
    PromotionAdd,
    BFormInput,
    BCard,
    BButton,
    BMedia,
    vSelect,
    BCol,
    BLink,
    BPagination,
    BOverlay,
    BTable,
    BRow,
    BBadge,
  },
  filters: {
    moment: function (date) {
      return moment(date).format("MM/DD/YYYY HH:mm:ss");
    },
  },
  setup() {
    //It is necessary to add this because in asynchronous methods you
    //cannot use the getCurrentInsance to translate since it is converted to null
    const vm = getCurrentInstance()

    const PROMOTION_APP_STORE_MODULE_NAME = "app-promotion";
    if (!store.hasModule(PROMOTION_APP_STORE_MODULE_NAME))
      store.registerModule(
          PROMOTION_APP_STORE_MODULE_NAME,
          promotionStoreModule
      );
    onUnmounted(() => {
      if (store.hasModule(PROMOTION_APP_STORE_MODULE_NAME))
        store.unregisterModule(PROMOTION_APP_STORE_MODULE_NAME);
    });

    const toast = useToast(ToastificationContent);

    const sortBy = ref("userName");
    const perPage = ref(10);
    const perPageOptions = ref([10, 25, 50, 100]);
    const isSortDirDesc = ref(false);
    const dataMeta = ref({
      from: 0,
      to: 0,
      of: 0,
    });
    const totalRows = ref(0);
    const currentPage = ref(1);

    const loadings = reactive({
      overlay: false,
    });

    const tableColumns = computed(() => [
      {key: "code", sortable: true, label: t('labels.promotion_code')},
      {key: "productId", label: t('product')},
      {key: "createdAt", label: t("created_at")},
      { key: "actions", label: t("labels.actions") },
    ]);

    const promotionList = ref([]);

    const searchQuery = ref("");

    const promotionAddComponent = ref(null);
    const promotionEditComponent = ref(null);
    const promotionDetailComponent = ref(null);

    const addExcludedUser = () => {
      promotionAddComponent.value.setModal(true);
    };

    const editExcludedUser = (data) => {
      promotionEditComponent.value.setEditData(data);
      promotionEditComponent.value.setModal(true);
    };

    const showDetailExcludeUser = (data) => {
      promotionDetailComponent.value.setDetailData(data);
      promotionDetailComponent.value.setModal(true);
    };

    const removePromotion = async (data) => {
      try {
        const confirm = await Swal.fire({
          title: vm.proxy.$t('buttons.delete'),
          text: vm.proxy.$t('are_sure_perform_operation'),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: vm.proxy.$t('buttons.yes'),
          cancelButtonText: vm.proxy.$t('buttons.no'),
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-primary mr-1",
            cancelButton: "btn btn-danger ml-1",
          },
        });
        if (confirm.value) {
          await store.dispatch("app-promotion/removePromotion", data._id);
          fetchData();
        }
      } catch (error) {
        toast({
          component: ToastificationContent,
          props: {
            title: await translatableText({
              text: axiosErrorHandle(error)
            }),
            icon: "XIcon",
            variant: "danger",
          },
        });
      }
    };

    const fetchData = () => {
      loadings.overlay = true;
      const whitelabelId = store.state.whitelabelCurrencyNabvar.whitelabel._id;

      const params = {
        // sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        perPage: perPage.value,
        page: currentPage.value,
        q: searchQuery.value,
        whitelabelId
      };
      store
          .dispatch("app-promotion/fetchPromotions", params)
          .then((response) => {
            loadings.overlay = false;
            const localItemsCount = response.data.menus
                ? response.data.menus.length
                : 0;
            promotionList.value = response.data.menus;
            totalRows.value = response.data.total;
            perPage.value = response.data.perPage;
            currentPage.value = response.data.page;
            dataMeta.value.from =
                response.data.perPage *
                (response.data.page - 1) *
                (localItemsCount ? 1 : 0);
            dataMeta.value.to =
                response.data.perPage * (response.data.page - 1) + localItemsCount;
            dataMeta.value.of = totalRows.value;
          })
          .catch(async (error) => {
            toast({
              component: ToastificationContent,
              props: {
                title: await translatableText({
                  text: axiosErrorHandle(error)
                }),
                icon: "XIcon",
                variant: "danger",
              },
            });
          })
          .finally(() => {
            loadings.overlay = false;
          });
    };

    //hooks
    onMounted(() => {
      fetchData();
    });

    return {
      tableColumns,
      promotionList,
      sortBy,
      perPage,
      perPageOptions,
      isSortDirDesc,
      searchQuery,
      dataMeta,
      totalRows,
      currentPage,
      editExcludedUser,
      removePromotion,
      addExcludedUser,
      promotionAddComponent,
      promotionDetailComponent,
      promotionEditComponent,
      fetchData,
      loadings,
      isSeePermission
    }
  },
}
</script>
